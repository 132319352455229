import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { inject, Component, ChangeDetectionStrategy, ViewChild, ContentChildren, Input, Directive, NgModule } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { maskitoInitialCalibrationPlugin } from '@maskito/core';
import { maskitoParseNumber, maskitoNumberOptionsGenerator, maskitoCaretGuard } from '@maskito/kit';
import { EMPTY_QUERY, CHAR_HYPHEN, CHAR_MINUS } from '@taiga-ui/cdk/constants';
import { tuiWatch } from '@taiga-ui/cdk/observables';
import { TUI_IS_IOS } from '@taiga-ui/cdk/tokens';
import { tuiClamp, tuiIsSafeToRound } from '@taiga-ui/cdk/utils/math';
import { tuiCreateToken, tuiProvideOptions, tuiPure } from '@taiga-ui/cdk/utils/miscellaneous';
import { TUI_DEFAULT_NUMBER_FORMAT, TUI_NUMBER_FORMAT } from '@taiga-ui/core/tokens';
import { tuiFormatNumber } from '@taiga-ui/core/utils/format';
import { AbstractTuiNullableControl, tuiAsControl, AbstractTuiTextfieldHost } from '@taiga-ui/legacy/classes';
import * as i3 from '@taiga-ui/legacy/components/primitive-textfield';
import { TuiPrimitiveTextfieldComponent, TuiPrimitiveTextfieldModule, TuiTextfieldComponent } from '@taiga-ui/legacy/components/primitive-textfield';
import * as i4 from '@taiga-ui/legacy/directives';
import { TUI_TEXTFIELD_SIZE, TUI_TEXTFIELD_WATCHED_CONTROLLER, TEXTFIELD_CONTROLLER_PROVIDER, TuiTextfieldControllerModule, TuiValueAccessorModule } from '@taiga-ui/legacy/directives';
import { tuiAsFocusableItemAccessor, tuiAsTextfieldHost } from '@taiga-ui/legacy/tokens';
import * as i5 from '@taiga-ui/polymorpheus';
import { PolymorpheusOutlet, PolymorpheusTemplate } from '@taiga-ui/polymorpheus';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i2 from '@maskito/angular';
import { MaskitoDirective } from '@maskito/angular';
import * as i6 from '@taiga-ui/core/components/button';
import { TuiButton } from '@taiga-ui/core/components/button';

/**
 * @deprecated use new version of {@link TUI_INPUT_NUMBER_DEFAULT_OPTIONS} (from @taiga-ui/kit) instead
 * TODO(v5): delete it
 */
const _c0 = ["*", [["input"]], [["tuiContent"]]];
const _c1 = ["*", "input", "tuiContent"];
function TuiInputNumberComponent_div_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const text_r2 = ctx.polymorpheusOutlet;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", text_r2, " ");
  }
}
function TuiInputNumberComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6);
    i0.ɵɵtemplate(1, TuiInputNumberComponent_div_4_ng_container_1_Template, 2, 1, "ng-container", 7);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const valueContent_r3 = i0.ɵɵreference(9);
    i0.ɵɵadvance();
    i0.ɵɵproperty("polymorpheusOutlet", valueContent_r3);
  }
}
function TuiInputNumberComponent_section_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "section", 8)(1, "button", 9);
    i0.ɵɵlistener("click.prevent", function TuiInputNumberComponent_section_5_Template_button_click_prevent_1_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.onArrow(ctx_r4.step));
    })("mousedown.prevent", function TuiInputNumberComponent_section_5_Template_button_mousedown_prevent_1_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.nativeFocusableElement == null ? null : ctx_r4.nativeFocusableElement.focus());
    });
    i0.ɵɵtext(2, " + ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "button", 9);
    i0.ɵɵlistener("click.prevent", function TuiInputNumberComponent_section_5_Template_button_click_prevent_3_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.onArrow(-ctx_r4.step));
    })("mousedown.prevent", function TuiInputNumberComponent_section_5_Template_button_mousedown_prevent_3_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r4 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r4.nativeFocusableElement == null ? null : ctx_r4.nativeFocusableElement.focus());
    });
    i0.ɵɵtext(4, " - ");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r4 = i0.ɵɵnextContext();
    const textfield_r6 = i0.ɵɵreference(1);
    i0.ɵɵadvance();
    i0.ɵɵproperty("appearance", textfield_r6.appearance)("disabled", !ctx_r4.canIncrement)("iconStart", ctx_r4.icons.up);
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("appearance", textfield_r6.appearance)("disabled", !ctx_r4.canDecrement)("iconStart", ctx_r4.icons.down);
  }
}
function TuiInputNumberComponent_ng_container_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function TuiInputNumberComponent_ng_template_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵprojection(0, 2);
  }
}
const TUI_INPUT_NUMBER_DEFAULT_OPTIONS = {
  icons: {
    up: '@tui.plus',
    down: '@tui.minus'
  },
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  step: 0
};
/**
 * @deprecated use new version of {@link TUI_INPUT_NUMBER_OPTIONS} (from @taiga-ui/kit) instead
 * TODO(v5): delete it
 */
const TUI_INPUT_NUMBER_OPTIONS = tuiCreateToken(TUI_INPUT_NUMBER_DEFAULT_OPTIONS);
/**
 * @deprecated use new version of {@link tuiInputNumberOptionsProvider} (from @taiga-ui/kit) instead
 * TODO(v5): delete it
 */
function tuiInputNumberOptionsProvider(options) {
  return tuiProvideOptions(TUI_INPUT_NUMBER_OPTIONS, options, TUI_INPUT_NUMBER_DEFAULT_OPTIONS);
}
const DEFAULT_MAX_LENGTH = 18;
/**
 * @deprecated use new version of {@link https://taiga-ui.dev/components/input-number tuiInputNumberOptionsProvider} (from @taiga-ui/kit) (property `valueTransformer`) instead
 * TODO(v5): delete it
 */
const TUI_NUMBER_VALUE_TRANSFORMER = tuiCreateToken();
/**
 * @deprecated use new version of {@link https://taiga-ui.dev/components/input-number TuiInputNumber} (from @taiga-ui/kit) instead
 * TODO(v5): delete it
 */
class TuiInputNumberComponent extends AbstractTuiNullableControl {
  constructor() {
    super(...arguments);
    this.isIOS = inject(TUI_IS_IOS);
    this.textfieldSize = inject(TUI_TEXTFIELD_SIZE);
    this.options = inject(TUI_INPUT_NUMBER_OPTIONS);
    this.unfinishedValue = '';
    this.polymorpheusValueContent = EMPTY_QUERY;
    this.valueTransformer = inject(TUI_NUMBER_VALUE_TRANSFORMER, {
      optional: true
    });
    this.numberFormat = TUI_DEFAULT_NUMBER_FORMAT;
    this.controller = inject(TUI_TEXTFIELD_WATCHED_CONTROLLER);
    this.numberFormat$ = inject(TUI_NUMBER_FORMAT).pipe(tuiWatch(this.cdr), takeUntilDestroyed()).subscribe(format => {
      this.numberFormat = format;
    });
    this.min = this.options.min;
    this.max = this.options.max;
    this.step = this.options.step;
  }
  get nativeFocusableElement() {
    return !this.textfield || this.computedDisabled ? null : this.textfield.nativeFocusableElement;
  }
  get focused() {
    return !!this.textfield?.focused;
  }
  get inputMode() {
    if (this.isIOS && this.isNegativeAllowed) {
      // iPhone does not have minus sign if inputMode is equal to 'numeric' / 'decimal'
      return 'text';
    }
    return !this.precision ? 'numeric' : 'decimal';
  }
  get calculatedMaxLength() {
    const decimalPart = !!this.precision && this.nativeValue.includes(this.numberFormat.decimalSeparator);
    const precision = decimalPart ? Math.min(this.precision + 1, 20) : 0;
    const takeThousand = this.numberFormat.thousandSeparator.repeat(5).length;
    return DEFAULT_MAX_LENGTH + precision + takeThousand;
  }
  get computedValue() {
    return this.focused ? this.nativeValue : this.formattedValue;
  }
  onValueChange(nativeValue) {
    const parsedValue = maskitoParseNumber(nativeValue, this.numberFormat.decimalSeparator);
    this.unfinishedValue = null;
    if (Number.isNaN(parsedValue)) {
      this.value = null;
      return;
    }
    if (this.isNativeValueNotFinished) {
      this.unfinishedValue = nativeValue;
      this.cdr.markForCheck();
      return;
    }
    if (parsedValue < this.computedMin || parsedValue > this.computedMax) {
      return;
    }
    this.value = parsedValue;
  }
  writeValue(value) {
    super.writeValue(value);
    this.nativeValue = this.formattedValue;
  }
  get size() {
    return this.textfieldSize.size;
  }
  get icons() {
    return this.options.icons;
  }
  get computedMin() {
    return this.computeMin(this.min, this.max);
  }
  get computedMax() {
    return this.computeMax(this.min, this.max);
  }
  get isNegativeAllowed() {
    return this.computedMin < 0;
  }
  get formattedValue() {
    return this.value !== null ? this.getFormattedValue(this.value || 0) : '';
  }
  get canDecrement() {
    return this.interactive && (this.value || 0) > this.computedMin;
  }
  get canIncrement() {
    return this.interactive && (this.value || 0) < this.computedMax;
  }
  get computedPrefix() {
    return this.controller.prefix;
  }
  get computedPostfix() {
    const postfix = this.controller.postfix;
    return postfix && ` ${postfix}`;
  }
  get mask() {
    return this.calculateMask(this.precision, this.numberFormat.decimalMode, this.numberFormat.decimalSeparator, this.numberFormat.thousandSeparator, this.computedMin, this.computedMax, this.computedPrefix, this.computedPostfix);
  }
  get nativeValue() {
    return this.nativeFocusableElement?.value || '';
  }
  set nativeValue(value) {
    if (!this.textfield || !this.nativeFocusableElement) {
      return;
    }
    this.textfield.value = value;
    this.nativeFocusableElement.value = value;
  }
  onArrow(step) {
    if (!step) {
      return;
    }
    this.value = tuiClamp((this.value || 0) + step, this.computedMin, this.computedMax);
    this.nativeValue = this.formattedValue;
  }
  onFocused(focused) {
    this.updateFocused(focused);
    const nativeNumberValue = this.unfinishedValue ? maskitoParseNumber(this.unfinishedValue, this.numberFormat.decimalSeparator) : this.nativeNumberValue;
    this.unfinishedValue = null;
    if (Number.isNaN(nativeNumberValue)) {
      this.nativeValue = focused && !this.readOnly ? this.computedPrefix + this.computedPostfix : '';
      this.value = null;
      return;
    }
    if (!focused) {
      this.value = nativeNumberValue;
      this.nativeValue = this.formattedValue;
    }
  }
  getFormattedValue(value) {
    return this.computedPrefix + tuiFormatNumber(value, {
      ...this.numberFormat,
      /**
       * Number can satisfy interval [Number.MIN_SAFE_INTEGER; Number.MAX_SAFE_INTEGER]
       * but its rounding can violate it.
       * Before BigInt support there is no perfect solution – only trade off.
       * No rounding is better than lose precision and incorrect mutation of already valid value.
       */
      precision: tuiIsSafeToRound(value, this.precision) ? this.precision : Infinity
    }).replace(CHAR_HYPHEN, CHAR_MINUS) + this.computedPostfix;
  }
  get isNativeValueNotFinished() {
    const nativeNumberValue = this.nativeNumberValue;
    return nativeNumberValue < 0 ? nativeNumberValue > this.computedMax : nativeNumberValue < this.computedMin;
  }
  get nativeNumberValue() {
    return maskitoParseNumber(this.nativeValue, this.numberFormat.decimalSeparator);
  }
  get precision() {
    return Number.isNaN(this.numberFormat.precision) ? 2 : this.numberFormat.precision;
  }
  computeMin(min, max) {
    return Math.min(this.valueTransformer?.fromControlValue(min) ?? min ?? this.options.min, this.valueTransformer?.fromControlValue(max) ?? max ?? this.options.max);
  }
  computeMax(min, max) {
    return Math.max(this.valueTransformer?.fromControlValue(min) ?? min ?? this.options.min, this.valueTransformer?.fromControlValue(max) ?? max ?? this.options.max);
  }
  // eslint-disable-next-line sonarjs/sonar-max-params
  calculateMask(precision, decimalMode, decimalSeparator, thousandSeparator, min, max, prefix, postfix) {
    const generatorParams = {
      decimalSeparator,
      thousandSeparator,
      min,
      max,
      prefix,
      postfix,
      precision,
      decimalZeroPadding: decimalMode === 'always'
    };
    const {
      plugins,
      ...options
    } = maskitoNumberOptionsGenerator(generatorParams);
    const initialCalibrationPlugin = maskitoInitialCalibrationPlugin(maskitoNumberOptionsGenerator({
      ...generatorParams,
      min: Number.MIN_SAFE_INTEGER,
      max: Number.MAX_SAFE_INTEGER
    }));
    return {
      ...options,
      plugins: [...plugins, initialCalibrationPlugin, maskitoCaretGuard(value => [prefix.length, value.length - postfix.length])]
    };
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiInputNumberComponent_BaseFactory;
      return function TuiInputNumberComponent_Factory(__ngFactoryType__) {
        return (ɵTuiInputNumberComponent_BaseFactory || (ɵTuiInputNumberComponent_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputNumberComponent)))(__ngFactoryType__ || TuiInputNumberComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TuiInputNumberComponent,
      selectors: [["tui-input-number"]],
      contentQueries: function TuiInputNumberComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, PolymorpheusOutlet, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.polymorpheusValueContent = _t);
        }
      },
      viewQuery: function TuiInputNumberComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(TuiPrimitiveTextfieldComponent, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.textfield = _t.first);
        }
      },
      hostVars: 1,
      hostBindings: function TuiInputNumberComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown.arrowDown", function TuiInputNumberComponent_keydown_arrowDown_HostBindingHandler() {
            return ctx.onArrow(-ctx.step);
          })("keydown.arrowUp", function TuiInputNumberComponent_keydown_arrowUp_HostBindingHandler() {
            return ctx.onArrow(ctx.step);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("data-size", ctx.size);
        }
      },
      inputs: {
        min: "min",
        max: "max",
        step: "step"
      },
      features: [i0.ɵɵProvidersFeature([tuiAsFocusableItemAccessor(TuiInputNumberComponent), tuiAsControl(TuiInputNumberComponent), TEXTFIELD_CONTROLLER_PROVIDER]), i0.ɵɵInheritDefinitionFeature],
      ngContentSelectors: _c1,
      decls: 10,
      vars: 14,
      consts: [["textfield", ""], ["valueContent", ""], ["tuiTextfieldPostfix", "", "tuiTextfieldPrefix", "", "tuiValueAccessor", "", 1, "t-textfield", 3, "focusedChange", "valueChange", "disabled", "focusable", "invalid", "maskito", "pseudoActive", "pseudoFocus", "pseudoHover", "readOnly", "value"], ["ngProjectAs", "tuiContent", "class", "t-value-content", 5, ["tuiContent"], 4, "ngIf"], ["class", "t-buttons", 4, "ngIf"], [4, "ngIf"], ["ngProjectAs", "tuiContent", 5, ["tuiContent"], 1, "t-value-content"], [4, "polymorpheusOutlet"], [1, "t-buttons"], ["size", "s", "tabindex", "-1", "tuiIconButton", "", "type", "button", 1, "t-button", 3, "click.prevent", "mousedown.prevent", "appearance", "disabled", "iconStart"]],
      template: function TuiInputNumberComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c0);
          i0.ɵɵelementStart(0, "tui-primitive-textfield", 2, 0);
          i0.ɵɵlistener("focusedChange", function TuiInputNumberComponent_Template_tui_primitive_textfield_focusedChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onFocused($event));
          })("valueChange", function TuiInputNumberComponent_Template_tui_primitive_textfield_valueChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onValueChange($event));
          });
          i0.ɵɵprojection(2);
          i0.ɵɵprojection(3, 1, ["ngProjectAs", "input", 5, ["input"]]);
          i0.ɵɵtemplate(4, TuiInputNumberComponent_div_4_Template, 2, 1, "div", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(5, TuiInputNumberComponent_section_5_Template, 5, 6, "section", 4)(6, TuiInputNumberComponent_ng_container_6_Template, 1, 0, "ng-container", 5);
          i0.ɵɵpipe(7, "async");
          i0.ɵɵtemplate(8, TuiInputNumberComponent_ng_template_8_Template, 1, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵproperty("disabled", ctx.computedDisabled)("focusable", ctx.focusable)("invalid", ctx.computedInvalid)("maskito", ctx.mask)("pseudoActive", ctx.pseudoActive)("pseudoFocus", ctx.computedFocused)("pseudoHover", ctx.pseudoHover)("readOnly", ctx.readOnly)("value", ctx.computedValue);
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("ngIf", ctx.polymorpheusValueContent.length);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", ctx.step);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(7, 12, ctx.polymorpheusValueContent.changes));
        }
      },
      dependencies: [i1.NgIf, i2.MaskitoDirective, i3.TuiPrimitiveTextfieldComponent, i3.TuiPrimitiveTextfieldDirective, i4.TuiTextfieldPrefixDirective, i4.TuiTextfieldPostfixDirective, i5.PolymorpheusOutlet, i4.TuiValueAccessorDirective, i6.TuiButton, i1.AsyncPipe],
      styles: ["[_nghost-%COMP%]{display:flex;border-radius:var(--tui-radius-m);text-align:start}[_nghost-%COMP%]   *:disabled[_nghost-%COMP%], *:disabled   [_nghost-%COMP%]{pointer-events:none}.t-textfield[_ngcontent-%COMP%]{flex:1;min-inline-size:0;max-inline-size:100%;border-radius:inherit;text-align:inherit}.t-textfield[_ngcontent-%COMP%]:not(:last-child){border-start-end-radius:0;border-end-end-radius:0}.t-value-content[_ngcontent-%COMP%]{inline-size:100%}.t-buttons[_ngcontent-%COMP%]{display:flex;flex-direction:column;align-items:stretch;margin-left:.125rem;border-radius:inherit}[data-size=s][_ngcontent-%COMP%] + .t-buttons[_ngcontent-%COMP%]{flex-direction:row-reverse}[data-size=s][_ngcontent-%COMP%] + .t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]:first-child{margin:0 0 0 .125rem;border-start-end-radius:inherit;border-end-end-radius:inherit}[data-size=s][_ngcontent-%COMP%] + .t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]:last-child{border-radius:0}[data-size=m][_ngcontent-%COMP%] + .t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]{inline-size:calc(var(--tui-height-m) * .75)}[data-size=l][_ngcontent-%COMP%] + .t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]{inline-size:calc(var(--tui-height-l) * .75)}.t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]{display:flex;flex:1;block-size:auto;align-items:center;justify-content:center;border-radius:0}.t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]:first-child{margin-bottom:.125rem;border-start-end-radius:inherit}.t-buttons[_ngcontent-%COMP%]   .t-button[_ngcontent-%COMP%]:last-child{border-end-end-radius:inherit}"],
      changeDetection: 0
    });
  }
}
__decorate([tuiPure], TuiInputNumberComponent.prototype, "computeMin", null);
__decorate([tuiPure], TuiInputNumberComponent.prototype, "computeMax", null);
__decorate([tuiPure], TuiInputNumberComponent.prototype, "calculateMask", null);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputNumberComponent, [{
    type: Component,
    args: [{
      standalone: false,
      selector: 'tui-input-number',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [tuiAsFocusableItemAccessor(TuiInputNumberComponent), tuiAsControl(TuiInputNumberComponent), TEXTFIELD_CONTROLLER_PROVIDER],
      host: {
        '[attr.data-size]': 'size',
        '(keydown.arrowDown)': 'onArrow(-step)',
        '(keydown.arrowUp)': 'onArrow(step)'
      },
      template: "<tui-primitive-textfield\n    #textfield\n    tuiTextfieldPostfix=\"\"\n    tuiTextfieldPrefix=\"\"\n    tuiValueAccessor\n    class=\"t-textfield\"\n    [disabled]=\"computedDisabled\"\n    [focusable]=\"focusable\"\n    [invalid]=\"computedInvalid\"\n    [maskito]=\"mask\"\n    [pseudoActive]=\"pseudoActive\"\n    [pseudoFocus]=\"computedFocused\"\n    [pseudoHover]=\"pseudoHover\"\n    [readOnly]=\"readOnly\"\n    [value]=\"computedValue\"\n    (focusedChange)=\"onFocused($event)\"\n    (valueChange)=\"onValueChange($event)\"\n>\n    <ng-content />\n    <ng-content\n        ngProjectAs=\"input\"\n        select=\"input\"\n    />\n    <div\n        *ngIf=\"polymorpheusValueContent.length\"\n        ngProjectAs=\"tuiContent\"\n        class=\"t-value-content\"\n    >\n        <ng-container *polymorpheusOutlet=\"valueContent as text\">\n            {{ text }}\n        </ng-container>\n    </div>\n</tui-primitive-textfield>\n\n<section\n    *ngIf=\"step\"\n    class=\"t-buttons\"\n>\n    <button\n        size=\"s\"\n        tabindex=\"-1\"\n        tuiIconButton\n        type=\"button\"\n        class=\"t-button\"\n        [appearance]=\"textfield.appearance\"\n        [disabled]=\"!canIncrement\"\n        [iconStart]=\"icons.up\"\n        (click.prevent)=\"onArrow(step)\"\n        (mousedown.prevent)=\"nativeFocusableElement?.focus()\"\n    >\n        +\n    </button>\n    <button\n        size=\"s\"\n        tabindex=\"-1\"\n        tuiIconButton\n        type=\"button\"\n        class=\"t-button\"\n        [appearance]=\"textfield.appearance\"\n        [disabled]=\"!canDecrement\"\n        [iconStart]=\"icons.down\"\n        (click.prevent)=\"onArrow(-step)\"\n        (mousedown.prevent)=\"nativeFocusableElement?.focus()\"\n    >\n        -\n    </button>\n</section>\n\n<ng-container *ngIf=\"polymorpheusValueContent.changes | async\" />\n\n<ng-template #valueContent>\n    <ng-content select=\"tuiContent\" />\n</ng-template>\n",
      styles: [":host{display:flex;border-radius:var(--tui-radius-m);text-align:start}:host :host-context(*:disabled){pointer-events:none}.t-textfield{flex:1;min-inline-size:0;max-inline-size:100%;border-radius:inherit;text-align:inherit}.t-textfield:not(:last-child){border-start-end-radius:0;border-end-end-radius:0}.t-value-content{inline-size:100%}.t-buttons{display:flex;flex-direction:column;align-items:stretch;margin-left:.125rem;border-radius:inherit}[data-size=s]+.t-buttons{flex-direction:row-reverse}[data-size=s]+.t-buttons .t-button:first-child{margin:0 0 0 .125rem;border-start-end-radius:inherit;border-end-end-radius:inherit}[data-size=s]+.t-buttons .t-button:last-child{border-radius:0}[data-size=m]+.t-buttons .t-button{inline-size:calc(var(--tui-height-m) * .75)}[data-size=l]+.t-buttons .t-button{inline-size:calc(var(--tui-height-l) * .75)}.t-buttons .t-button{display:flex;flex:1;block-size:auto;align-items:center;justify-content:center;border-radius:0}.t-buttons .t-button:first-child{margin-bottom:.125rem;border-start-end-radius:inherit}.t-buttons .t-button:last-child{border-end-end-radius:inherit}\n"]
    }]
  }], null, {
    textfield: [{
      type: ViewChild,
      args: [TuiPrimitiveTextfieldComponent]
    }],
    polymorpheusValueContent: [{
      type: ContentChildren,
      args: [PolymorpheusOutlet, {
        descendants: true
      }]
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    step: [{
      type: Input
    }],
    computeMin: [],
    computeMax: [],
    calculateMask: []
  });
})();

/**
 * @deprecated use new version of {@link https://taiga-ui.dev/components/input-number TuiInputNumber} (from @taiga-ui/kit) instead
 * TODO(v5): delete it
 */
class TuiInputNumberDirective extends AbstractTuiTextfieldHost {
  get value() {
    return this.host.computedValue;
  }
  onValueChange(value) {
    this.host.onValueChange(value);
  }
  ngDoCheck() {
    if (!this.host.nativeFocusableElement) {
      return;
    }
    this.host.nativeFocusableElement.inputMode = this.host.inputMode;
    this.host.nativeFocusableElement.maxLength = this.host.nativeFocusableElement.maxLength > 0 ? this.host.nativeFocusableElement.maxLength : this.host.calculatedMaxLength;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵTuiInputNumberDirective_BaseFactory;
      return function TuiInputNumberDirective_Factory(__ngFactoryType__) {
        return (ɵTuiInputNumberDirective_BaseFactory || (ɵTuiInputNumberDirective_BaseFactory = i0.ɵɵgetInheritedFactory(TuiInputNumberDirective)))(__ngFactoryType__ || TuiInputNumberDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TuiInputNumberDirective,
      selectors: [["tui-input-number"]],
      features: [i0.ɵɵProvidersFeature([tuiAsTextfieldHost(TuiInputNumberDirective)]), i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputNumberDirective, [{
    type: Directive,
    args: [{
      standalone: false,
      selector: 'tui-input-number',
      providers: [tuiAsTextfieldHost(TuiInputNumberDirective)]
    }]
  }], null, null);
})();

/**
 * @deprecated use new version of {@link https://taiga-ui.dev/components/input-number TuiInputNumber} (from @taiga-ui/kit) instead
 * TODO(v5): delete it
 */
class TuiInputNumberModule {
  static {
    this.ɵfac = function TuiInputNumberModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || TuiInputNumberModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TuiInputNumberModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [CommonModule, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiValueAccessorModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiInputNumberModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MaskitoDirective, TuiPrimitiveTextfieldModule, TuiTextfieldControllerModule, TuiValueAccessorModule, PolymorpheusOutlet, PolymorpheusTemplate, TuiButton],
      declarations: [TuiInputNumberComponent, TuiInputNumberDirective],
      exports: [TuiInputNumberComponent, TuiInputNumberDirective, TuiTextfieldComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_INPUT_NUMBER_DEFAULT_OPTIONS, TUI_INPUT_NUMBER_OPTIONS, TUI_NUMBER_VALUE_TRANSFORMER, TuiInputNumberComponent, TuiInputNumberDirective, TuiInputNumberModule, tuiInputNumberOptionsProvider };
